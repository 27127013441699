import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WebriQForm from "@webriq/gatsby-webriq-form"
import "../components/redesign/pages/contact-page.scss"

//images
import Logo from "@images/logo-image.svg"
import LogoText from "@images/logo-text-dark-centered.svg"
import facebook from "@images/facebook_dark.svg"
import instagram from "@images/instagram_dark.svg"
import linkedin from "@images/linkedin_dark.svg"
import youtube from "@images/youtube_dark.svg"
import pdfIcon from "@images/pdf-icon.svg"

const Contact = () => (
	<Layout>
		<SEO title="Company" description="" />
		<div className="contact-page-wrapper" id="company">
			<div className="content-wrapper">
				<div className="container">
					<h2 className="lined-heading">Company</h2>
					<div className="indented">
						<h3 className="text-blue">Bright Ideas - Innovative Solutions</h3>
						<p>
							Founded in 1989, American Lighting, Inc. manufactures a wide range
							of lighting solutions for your residential, commercial and
							specialty lighting needs. We pride ourselves in being a lighting
							solutions provider by offering our customers a broad line of
							innovative and high quality lighting products. Continued
							innovation is a core strategic initiative at American Lighting,
							and we are committed to developing new products by utilizing the
							latest lighting technologies and designs. We pledge to deliver
							superior customer service by employing and training a team of
							highly experienced lighting professionals.
						</p>

						<p>
							American Lighting's knowledgeable and dedicated sales team is here
							to provide you with product information as well as answer any
							questions you may have.
						</p>
					</div>
				</div>
			</div>
			<div className="content-wrapper">
				<div className="container">
					<h2 className="lined-heading">Sales Divisions</h2>
					<div className="indented">
						<div className="row justify-content-between">
							<div className="col-lg-3 mb-5 mb-lg-0">
								<h3 className="lined-heading">Electrical Distribution</h3>
								<div className="pb-4">
									<h4 className="text-blue">Jason Elrod</h4>
									<p className="staff-details">
										<span className="title">VP of Sales & Marketing</span>
										<br />
										<a href="mailto:JElrod@AmericanLighting.com">
											JElrod@AmericanLighting.com
										</a>
										<br />
										<a href="tel:303.923.1145">Phone: 303.923.1145</a>
									</p>
								</div>
								<h4 className="text-blue">Alyssa Stone</h4>
								<p className="staff-details">
									<span className="title">Regional Sales Manager</span>
									<br />
									<a href="mailto:AStone@AmericanLighting.com">
										AStone@AmericanLighting.com
									</a>
									<br />
									<a href="tel:303.923.1145">Phone: 303.923.1175</a>
								</p>
							</div>
							<div className="col-lg-3 mb-5 mb-lg-0">
								<h3 className="lined-heading">Lighting Showrooms</h3>
								<h4 className="text-blue">Jennifer Kirkpatrick</h4>
								<p className="staff-details">
									<span className="title">National Sales Manager</span>
									<br />
									<a href="mailto:JKirkpatrick@AmericanLighting.com">
										JKirkpatrick@AmericanLighting.com
									</a>
									<br />
									<a href="tel:303.923.1173">Phone: 303.923.1173</a>
								</p>
							</div>

							<div className="col-lg-3">
								<h3 className="lined-heading">Seasonal Lighting</h3>
								<div className="pb-4">
									<h4 className="text-blue">Richard Lyford</h4>
									<p className="staff-details">
										<span className="title">National Sales Manager</span>
										<br />
										<a href="mailto:RLyford@AmericanLighting.com">
											RLyford@AmericanLighting.com
										</a>
										<br />
										<a href="tel:303.923.1171">Phone: 303.923.1171</a>
									</p>
								</div>
								<h4 className="text-blue">Brent Faulkner</h4>
								<p className="staff-details">
									<span className="title">Regional Sales Manager</span>
									<br />
									<a href="mailto:BFaulkner@AmericanLighting.com">
										BFaulkner@AmericanLighting.com
									</a>
									<br />
									<a href="tel:303.923.1153">Phone: 303.923.1153</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="content-wrapper" id="contact">
				<div className="container">
					<h2 className="lined-heading">Contact Us</h2>
					<div className="indented" style={{ paddingTop: "5px" }}>
						<div className="row">
							<div className="col-lg-6 mb-5 mb-lg-0">
								<div className="contact-details">
									<div className="d-flex align-items-center">
										<img src={Logo} className="logo-icon mr-4" alt="" />
										<img src={LogoText} className="logo-text" alt="" />
									</div>
									<div style={{ margin: "2.25rem 0" }}>
										<span className="company-name">
											AMERICAN LIGHTING INC.
											<br />
											11775 EAST 45TH AVENUE. DENVER, CO 80239
										</span>
										<br />
										<span className="company-main">
											Main:{" "}
											<a className="text-blue" href="tel:800.880.1180">
												800.880.1180
											</a>
											&nbsp; &nbsp; &nbsp; Customer Service:{" "}
											<a className="text-blue" href="tel:800.285.8051">
												800.285.8051
											</a>
											<br />
											Monday - Friday. 8am - 5pm MST
										</span>
									</div>
								</div>
								<div className="socials">
									<a
										href="https://www.facebook.com/americanlightinginc/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img src={facebook} alt="Facebook" width="36px" />
									</a>
									<a
										href="https://www.instagram.com/americanlightinginc/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img src={instagram} alt="Instagram" width="36px" />
									</a>
									<a
										href="https://www.linkedin.com/company/american-lighting"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img src={linkedin} alt="LinkedIn" width="36px" />
									</a>
									<a
										href="https://www.youtube.com/c/AmericanLightingInc/about"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img src={youtube} alt="YouTube" width="36px" />
									</a>
								</div>
							</div>
							<div className="col-lg-6 mt-3">
								<h3 className="lined-heading">Departments</h3>
								<div className="departments-contact">
									<p>
										<span className="dept-name">SALES:</span>{" "}
										<a
											className="text-blue"
											href="mailto:Sales@AmericanLighting.com"
										>
											Sales@AmericanLighting.com
										</a>
									</p>
									<p>
										<span className="dept-name">QUOTES:</span>{" "}
										<a
											className="text-blue"
											href="mailto:Quotes@AmericanLighting.com"
										>
											Quotes@AmericanLighting.com
										</a>
									</p>
									<p>
										<span className="dept-name">ORDERS:</span>{" "}
										<a
											className="text-blue"
											href="mailto:Orders@AmericanLighting.com"
										>
											Orders@AmericanLighting.com
										</a>
									</p>
									<p>
										<span className="dept-name">TECHNICAL SUPPORT:</span>{" "}
										<a
											className="text-blue"
											href="mailto:TechnicalSupport@AmericanLighting.com"
										>
											TechnicalSupport@AmericanLighting.com
										</a>
									</p>
								</div>
							</div>
						</div>
						<div className="mt-5">
							<h3 className="text-blue lined-heading email-us-heading">
								Email Us
							</h3>
							<div>
								<WebriQForm
									method="POST"
									data-form-id="5e71ceaceca8060a7cbdd0d5"
									name="Contact"
									className="contact-form"
									data-thank-you-url="/thank-you"
								>
									<div className="row">
										<div className="col-md-6">
											<div className="form-group">
												<input
													className="form-control"
													type="text"
													placeholder="Your Name (Required:)"
													name="Name"
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													className="form-control"
													type="text"
													placeholder="Company or Agency (Required):"
													name="CompanyAgency"
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													className="form-control"
													type="phone"
													placeholder="Phone Number (Required):"
													name="PhoneNumber"
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													className="form-control"
													type="text"
													placeholder="Role (i.e.: Distributor, Showroom, Contractor):"
													name="Role"
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													className="form-control"
													type="email"
													placeholder="Email (Required):"
													name="Email"
													required
												/>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group">
												<input
													className="form-control"
													type="text"
													placeholder="Company Location:"
													name="CompanyLocation"
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="form-group">
												<textarea
													className="form-control"
													placeholder="What Can We Assist You With?:"
													rows="7"
													name="Message"
													required
												/>
											</div>
										</div>
										<div className="col-md-12">
											<div className="centered-captcha">
												<div className="webriq-recaptcha" />
											</div>
										</div>
										<div className="col-md-6 mx-auto">
											<button
												className="btn btn-primary btn-block"
												type="submit"
											>
												Submit
											</button>
										</div>
									</div>
								</WebriQForm>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="content-wrapper"
				style={{ paddingTop: "78px" }}
				id="support-forms"
			>
				<div className="container">
					<h2 className="lined-heading">Support & Forms</h2>
					<div className="support-forms">
						<div className="row justify-content-center">
							<div className="col-md-4 col-lg-3 mb-5 mb-md-0 text-center">
								<a
									href="https://files.americanlighting.webriq.me/assets/form_al_creditapplication_rev1605_j265pg.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={pdfIcon}
										width="109px"
										className="img-fluid d-block mx-auto mb-2"
										alt=""
									/>
									<span className="pdf-link text-blue text-uppercase d-block">
										Credit Application
									</span>
								</a>
							</div>
							<div className="col-md-4 col-lg-3 mb-5 mb-md-0 text-center">
								<a
									href="https://files.americanlighting.webriq.me/assets/form_al_cc_authorization_rev1605_mmfxig.pdf"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img
										src={pdfIcon}
										width="109px"
										className="img-fluid d-block mx-auto mb-2"
										alt=""
									/>
									<span className="pdf-link text-blue text-uppercase d-block">
										Credit Authorization
									</span>
								</a>
							</div>
							<div className="col-md-4 col-lg-3 text-center">
								<a href="https://app.smartsheet.com/b/form/91a23b9353ad4d3e90b7a62f8f632815">
									<img
										src={pdfIcon}
										width="109px"
										className="img-fluid d-block mx-auto mb-2"
										alt=""
									/>
									<span className="pdf-link text-blue text-uppercase d-block">
										RGA Request
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default Contact
